import axios from "axios";
import { GetContextRequestType } from "../types/types";

const serverURL = process.env.REACT_APP_SERVER_URL;

const getContext = async (
  question: string | undefined,
  currentChatId: string,
  accessLevelName: string[] | undefined,
  siteId: string | undefined,
  humanMessageId: string
) => {
  const payload = {
    question: question,
    chat_id: currentChatId,
    access_level_name: accessLevelName,
    human_message_id: humanMessageId,
  };
  try {
    const res: GetContextRequestType = await axios.post(
      `${serverURL}/${siteId}/get-context`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(`${siteId}_access_token`),
        },
      }
    );
    return res.data.context;
  } catch (error) {
    console.log(error);
  }
};

const getContextWidget = async (
  question: string | undefined,
  accessLevelName: string[] | undefined,
  siteId: string | undefined
) => {
  const payload = {
    question: question,
    access_level_name: accessLevelName,
  };
  try {
    const res: GetContextRequestType = await axios.post(
      `${serverURL}/${siteId}/get-context-widget`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(`${siteId}_access_token`),
        },
      }
    );
    return res.data.context;
  } catch (error) {
    console.log(error);
  }
};

const sendAnswerToDb = async (
  answer: string | undefined,
  chatId: string | number,
  siteId: string | undefined,
  aiMessageId: string,
  intent: string | undefined
) => {
  try {
    const payload = {
      chat_id: chatId,
      answer: answer,
      ai_message_id: aiMessageId,
      intent: intent,
    };
    await axios.post(`${serverURL}/${siteId}/add-response`, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(`${siteId}_access_token`),
      },
    });
  } catch (error) {
    console.log(error);
  }
};

const sendAnswerToDbCrawler = async (
  answer: string | undefined,
  chatId: string | number,
  siteId: string | undefined,
  aiMessageId: string
) => {
  try {
    const payload = {
      chat_id: chatId,
      answer: answer,
      ai_message_id: aiMessageId,
    };
    await axios.post(`${serverURL}/${siteId}/add-response`, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(`${siteId}_access_token`),
      },
    });
  } catch (error) {
    console.log(error);
  }
};

const saveMessageInDB = async (
  chatId: string,
  question: string,
  chatMessageId: string,
  siteId: string
) => {
  try {
    const payload = {
      chat_id: chatId,
      question: question,
      chat_message_id: chatMessageId,
    };

    await axios.post(`${serverURL}/${siteId}/save-message`, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(`${siteId}_access_token`),
      },
    });
  } catch (error) {
    console.log(error);
  }
};

export {
  sendAnswerToDb,
  getContext,
  sendAnswerToDbCrawler,
  saveMessageInDB,
  getContextWidget,
};
