/* eslint-disable react-hooks/exhaustive-deps */
import style from "./NewUI.module.css";
import NewChatAndSidebar from "./ChatAndSidebar";
import { Link, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import DataUpload from "./DataUpload";
import PromptTemplates from "./PromptTemplates";
import Group from "./Group";
import axios from "axios";
import { StoreContext } from "../../data/Context";
// import TasksLibrary from "./TasksLibrary";
// import Crawler from "./Crawler";
// import ChatBotNewWorkflow from "./ChatBotNewWorkflow";
import {
  FetchUserDataRequestType,
  GetSiteConfigRequestType,
} from "../../types/types";
import WebRTCHandler from "./WebRTCHandler";
import OpenAIAdminPanel from "./OpenAIAdminPanel";

const NewUI = () => {
  const { siteId } = useParams();

  const serverURL = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();
  const state = useContext(StoreContext);
  const [selectedRoute, setSelectedRoute] = useState<string>("KI-Assistenten");
  let routesAndNames = [
    { routeName: "KI-Assistenten", path: "" },
    { routeName: "Realtime", path: "realtime" },
    // { routeName: "Webseiten erfassen", path: "crawler" },
    // { routeName: "KI Workflows", path: "tasks-library" },
    // { routeName: "Rechtsberater", path: "legal-advisor" },
    { routeName: "Wissensbasis", path: "database" },
    { routeName: "Berechtigungen", path: "group" },
    { routeName: "Vorlagen", path: "prompt-templates" },
  ];

  if (siteId && siteId === "bvmw") {
    routesAndNames = [
      { routeName: "KI-Assistenten", path: "" },
      // { routeName: "Webseiten erfassen", path: "crawler" },
      { routeName: "Wissensbasis", path: "database" },
      { routeName: "Berechtigungen", path: "group" },
    ];
  }

  if (state.store.group_role === "CEO") {
    routesAndNames.push({
      routeName: "OpenAI Admin Panel",
      path: "openai-admin-panel",
    });
  }

  const getSiteConfig = async () => {
    try {
      const res: GetSiteConfigRequestType = await axios.get(
        `${serverURL}/${siteId}/get-site-config`
      );
      if (res && res.data && res.data.result) {
        const { color_primary, logo } = res.data.result;

        state.setStore((prevState) => {
          const newState = {
            ...prevState,
            config: {
              ...prevState.config,
              colorPrimary:
                color_primary || prevState.config.colorPrimary || "",
              logo: logo || prevState.config.logo || "",
            },
          };
          return newState;
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getLastPathSegment = (path: string) => {
    const segments = path.split("/").filter(Boolean);
    return segments.pop();
  };

  useEffect(() => {
    const path = window.location.pathname;
    const lastSegment = getLastPathSegment(path);
    const route = routesAndNames.find((route) => route.path === lastSegment);
    if (route) {
      setSelectedRoute(route.routeName);
    } else {
      setSelectedRoute("KI-Assistenten");
    }
  }, [window.location.pathname]);

  const fetchUserData = async (): Promise<any> => {
    try {
      const response: FetchUserDataRequestType = await axios.post(
        `${serverURL}/${siteId}/get-user-data`,
        undefined,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + localStorage.getItem(`${siteId}_access_token`),
          },
        }
      );
      const userData = response.data.user_data;
      if (userData) {
        state.setStore((prevState) => ({
          ...prevState,
          userId: userData.id,
          email: userData.email,
          username: userData.username,
          chatList: userData.chats_id,
          chatInUse: userData.lastUsedChat,
          group_id: userData.group_id,
          group_role: userData.group_role,
          template_in_use: userData.template_in_use,
          assistant_id: userData.assistant_id,
        }));
      }
      return response.data.user_data;
    } catch (error) {
      console.log(error);
      navigate(`/${siteId}/`);
    }
  };

  const logout = () => {
    localStorage.removeItem(`${siteId}_access_token`);
    navigate(`/${siteId}/`);
  };

  useEffect(() => {
    getSiteConfig();
  }, []);

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <div className={style.main}>
      <div className={style.headerContainer}>
        <img
          style={{ height: 50 }}
          src={`/images/${state.store.config.logo}`}
          alt="logo"
        />
        <button
          style={{ backgroundColor: state.store.config.colorPrimary }}
          className={style.logoutBtn}
          onClick={logout}
        >
          Logout
        </button>
      </div>

      <div className={style.contentContainer}>
        <div className={style.navBarContainer}>
          <div className={style.navBarItemsWrapper}>
            {routesAndNames.map(
              (
                routeAndName: { routeName: string; path: string },
                index: number
              ) => (
                <Link
                  style={{ textDecoration: "none" }}
                  key={index}
                  to={routeAndName.path}
                >
                  <span
                    onClick={() => setSelectedRoute(routeAndName.routeName)}
                    className={
                      routeAndName.routeName === selectedRoute
                        ? style.navBarItemSelected
                        : style.navBarItem
                    }
                    style={
                      routeAndName.routeName === selectedRoute
                        ? {
                            color: state.store.config.colorPrimary,
                          }
                        : {}
                    }
                  >
                    {routeAndName.routeName}
                  </span>
                </Link>
              )
            )}
          </div>
        </div>
        <Routes>
          <Route path="" element={<NewChatAndSidebar />} />
          <Route path="database" element={<DataUpload />} />
          <Route path="realtime" element={<WebRTCHandler />} />
          {/* <Route path="crawler" element={<Crawler />} /> */}
          {/* <Route path="legal-advisor" element={<ChatBotNewWorkflow />} /> */}
          <Route path="group" element={<Group />} />
          <Route
            path="prompt-templates"
            element={<PromptTemplates refresh={fetchUserData} />}
          />
          <Route path="openai-admin-panel" element={<OpenAIAdminPanel />} />
          {/* <Route path="tasks-library" element={<TasksLibrary />} /> */}
        </Routes>
      </div>
    </div>
  );
};

export default NewUI;
