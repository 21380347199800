import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { StoreContext } from "../../data/Context";
import OpenAI from "openai";
import style from "./NewUI.module.css";
import { GetPromptTemplateContentByIdRequestType } from "../../types/types";

const OpenAIAdminPanel = () => {
  const { siteId } = useParams();
  const serverURL = process.env.REACT_APP_SERVER_URL;
  const state = useContext(StoreContext);

  const [assistants, setAssistants] = useState<OpenAI.Beta.Assistant[]>([]);
  const [realtimeTemplate, setRealtimeTemplate] = useState<string>("");

  const getAllAssistants = async () => {
    const res = await axios.get(`${serverURL}/${siteId}/get-all-assistants`);
    if (res) {
      setAssistants(res.data.res);
    }
  };

  const changeActiveAssistant = async (assistantId: string) => {
    await axios.post(`${serverURL}/${siteId}/change-assistant`, {
      assistant_id: assistantId,
      user_id: state.store.userId,
    });
    state.setStore((prevState) => ({
      ...prevState,
      assistant_id: assistantId,
    }));
  };

  useEffect(() => {
    getAllAssistants();
    getPromptTemplateContent();
  }, []);

  const getPromptTemplateContent = async () => {
    try {
      const res: GetPromptTemplateContentByIdRequestType = await axios.post(
        `${serverURL}/${siteId}/get-prompt-template-content-by-id`,
        { prompt_template_id: "0" }
      );
      setRealtimeTemplate(() => {
        const template = res.data.result;
        return template;
      });
    } catch (error) {
      console.error(error);
    }
  };

  const changePromptTemplate = async () => {
    try {
      await axios.post(
        `${serverURL}/${siteId}/change-prompt-template-realtime`,
        {
          template_string: realtimeTemplate,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + localStorage.getItem(`${siteId}_access_token`),
          },
        }
      );
    } catch (error) {
      console.error("Error change prompt template - " + error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        height: "60%",
        width: "100%",
        flexDirection: "row",
      }}
    >
      <div className={style.panelItemWrapper}>
        <h2>Assistants</h2>
        <div
          className={style.realtimeMessagesWrapper}
          style={{
            width: "100%",
            flexDirection: "row",
            overflowX: "auto",
            flexWrap: "wrap",
          }}
        >
          {assistants.length > 0 &&
            assistants.map((assistant: OpenAI.Beta.Assistant) => (
              <div className={style.assistantTile} key={assistant.id}>
                <div>Name: {assistant.name}</div>
                <div>Model: {assistant.model}</div>
                <div
                  style={{
                    maxHeight: 200,
                    overflowY: "auto",
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "lightGrey",
                  }}
                >
                  Instructions: <br /> <br />
                  {assistant.instructions}
                </div>
                {state.store.assistant_id !== assistant.id && (
                  <button
                    className={style.editPromptTemplateCancelButton}
                    onClick={() => changeActiveAssistant(assistant.id)}
                  >
                    Activate
                  </button>
                )}
              </div>
            ))}
        </div>
      </div>
      <div className={style.panelItemWrapper} style={{ gap: 10 }}>
        <h2>Realtime instruction</h2>
        <textarea
          value={realtimeTemplate}
          onChange={(e) => setRealtimeTemplate(e.currentTarget.value)}
          style={{ width: "100%", height: "100%" }}
        ></textarea>
        <button
          onClick={changePromptTemplate}
          className={style.createNewTemplateButton}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default OpenAIAdminPanel;
