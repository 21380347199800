/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { StoreContext } from "../../data/Context";
import style from "./NewUI.module.css";
import "../../App.css";

type RealtimeChatType = {
  sender: string; // 'ai' or 'user'
  text: string;
  respId?: string;
};

const WebRTCHandler: React.FC = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [peerConnection, setPeerConnection] =
    useState<RTCPeerConnection | null>(null);
  const [dataChannel, setDataChannel] = useState<RTCDataChannel | null>(null);
  const [audioElement, setAudioElement] = useState<HTMLAudioElement | null>(
    null
  );
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [isInitializing, setIsInitializing] = useState(false);
  const [currentChat, setCurrentChat] = useState<RealtimeChatType[]>([
    // { sender: "ai", text: "Some chatbot message" },
  ]);

  const { siteId } = useParams();
  const serverURL = process.env.REACT_APP_SERVER_URL;
  const state = useContext(StoreContext);

  const init = async () => {
    try {
      setIsInitializing(true);

      const tokenResponse = await fetch(`${serverURL}/${siteId}/session`);
      const data = await tokenResponse.json();
      const EPHEMERAL_KEY = data.client_secret.value;

      const pc = new RTCPeerConnection();
      setPeerConnection(pc);

      const audioEl = document.createElement("audio");
      audioEl.autoplay = true;
      setAudioElement(audioEl);
      pc.ontrack = (e) => {
        if (audioEl) {
          audioEl.srcObject = e.streams[0];
        }
      };

      const ms = await navigator.mediaDevices.getUserMedia({ audio: true });
      setMediaStream(ms);
      ms.getTracks().forEach((track) => pc.addTrack(track));

      const dc = pc.createDataChannel("oai-events");
      setDataChannel(dc);

      dc.addEventListener("message", (e) => {
        const event = JSON.parse(e.data);
        // console.log("Received event:", event);
        setCurrentChat((prevChat) => {
          if (event.type === "response.created") {
            if (currentChat.length === 0) {
              return [
                ...prevChat,
                {
                  text: "",
                  sender: "ai",
                  respId: event.response.id,
                },
              ];
            }
            if (
              currentChat.length !== 0 &&
              currentChat[currentChat.length - 1].text
            ) {
              return [
                ...prevChat,
                {
                  text: "",
                  sender: "ai",
                  respId: event.response.id,
                },
              ];
            }
          }

          if (event.type === "response.audio_transcript.delta") {
            let chunk = event.delta;

            if (prevChat.length === 0) return prevChat;

            return prevChat.map((msg, index) =>
              index === prevChat.length - 1
                ? { ...msg, text: msg.text + chunk }
                : msg
            );
          }

          return prevChat;
        });
      });

      const offer = await pc.createOffer();
      await pc.setLocalDescription(offer);

      const sdpResponse = await fetch(
        "https://api.openai.com/v1/realtime?model=gpt-4o-realtime-preview-2024-12-17",
        {
          method: "POST",
          body: offer.sdp,
          headers: {
            Authorization: `Bearer ${EPHEMERAL_KEY}`,
            "Content-Type": "application/sdp",
          },
        }
      );

      const answer = {
        type: "answer" as const,
        sdp: await sdpResponse.text(),
      };
      await pc.setRemoteDescription(answer);

      setIsConnected(true);
      setIsInitializing(false);
    } catch (error) {
      console.error("Error during WebRTC setup:", error);
      setIsInitializing(false);
    }
  };

  const handleCallEnd = () => {
    if (peerConnection) {
      peerConnection.close();
      setPeerConnection(null);
      setIsConnected(false);
    }
    if (audioElement) {
      audioElement.pause();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        maxHeight: "90%",
        alignItems: "center",
      }}
    >
      <h1 style={{ color: state.store.config.colorPrimary }}>
        Realtime Voice Assistant
      </h1>

      {!isConnected && currentChat.length === 0 ? (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "60%",
            }}
          >
            <button
              onClick={init}
              disabled={isInitializing}
              className={style.realtimeStartButton}
            >
              {isInitializing ? (
                <div className={style.spinnerLoader} />
              ) : (
                "Start"
              )}
            </button>
          </div>
        </>
      ) : (
        <>
          <div className={style.realtimeMessagesWrapper}>
            {currentChat.length !== 0 &&
              currentChat.map((message: RealtimeChatType, index: number) => (
                <div className={style.realtimeMessage} key={index}>
                  {message.text}
                </div>
              ))}
          </div>
          <div className={style.realtimeStopButtonWrapper}>
            <button
              className={
                isConnected
                  ? style.realtimeStopButton
                  : style.realtimeStartSmallButton
              }
              onClick={handleCallEnd}
            >
              {isConnected ? "STOP" : "START"}
            </button>
          </div>
          {isConnected && (
            <div className="hero_area">
              <svg
                className="waves"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 24 150 28"
                preserveAspectRatio="none"
                shapeRendering="auto"
              >
                <defs>
                  <path
                    id="gentle-wave"
                    d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                  />
                </defs>
                <g className="parallax">
                  <use
                    xlinkHref="#gentle-wave"
                    x="48"
                    y="0"
                    fill="rgba(0, 131, 117,0.7"
                  />
                  <use
                    xlinkHref="#gentle-wave"
                    x="48"
                    y="3"
                    fill="rgba(0, 131, 117,0.5)"
                  />
                  <use
                    xlinkHref="#gentle-wave"
                    x="48"
                    y="5"
                    fill="rgba(0, 131, 117,0.3)"
                  />
                  <use
                    xlinkHref="#gentle-wave"
                    x="48"
                    y="7"
                    fill="rgb(0, 131, 117)"
                  />
                </g>
              </svg>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default WebRTCHandler;
